class MobileMenu {
  constructor() {
    this.header = document.querySelector(".header");
    this.menu = document.querySelector(".header__nav");
    this.mobileMenu = document.querySelector(".mobile-menu");
    this.toggleMenuBtn = document.querySelector(
      ".header__top-bar__menu-trigger"
    );
    this.closeBtn = document.querySelector(".closing-element");
    this.subMenuTriggers = document.querySelectorAll(".menu-item-has-children");
    this.subMenus = document.querySelectorAll(".sub-menu");
    this.body = document.querySelector("body");

    this.setInitialAriaStateSubMenus();
    this.events();
  }

  events() {
    this.toggleMenuBtn.addEventListener("click", () => {
      this.toggleMenu();
      if (window.innerWidth > 768) {
        this.hideMobileMenu();
      }
    });
    this.closeBtn.addEventListener("click", () => {
      this.toggleMenu();
      if (window.innerWidth > 768) {
        this.hideMobileMenu();
      }
    });
  }

  toggleMenu() {
    this.header.classList.toggle("is-open");
    this.menu.classList.toggle("is-active");
    this.closeBtn.classList.toggle("is-open");
    this.toggleScrollLock();
  }

  hideMobileMenu() {
    if (this.header.classList.contains("is-open")) {
      this.mobileMenu.classList.add("hidden");
    } else {
      this.mobileMenu.classList.remove("hidden");
    }
  }

  toggleScrollLock() {
    if (this.header.classList.contains("is-open")) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100svh";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "auto";
    }
  }

  toggleSubMenus(e) {
    const parentEl = e.target.closest(".menu-item-has-children");
    const subMenu = parentEl.querySelector(".sub-menu");
    parentEl.classList.toggle("is-active");
    subMenu.classList.toggle("active");

    let subMenuAriaExpandedState = subMenu.getAttribute("aria-expanded");
    if (subMenuAriaExpandedState == "true") {
      subMenuAriaExpandedState = "false";
    } else {
      subMenuAriaExpandedState = "true";
    }
    subMenu.setAttribute("aria-expanded", subMenuAriaExpandedState);
  }

  setInitialAriaStateSubMenus() {
    this.subMenus.forEach((subMenu) => {
      subMenu.setAttribute("aria-expanded", "false");
    });
  }
}

export default MobileMenu;
