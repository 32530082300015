class QuantitySelector {
  constructor() {
    this.minusBtns = document.querySelectorAll(
      ".quantity__selector__wrapper .minus"
    );
    this.plusBtns = document.querySelectorAll(
      ".quantity__selector__wrapper .plus"
    );

    if (this.plusBtns.length != 0) {
      this.events();
    }
  }

  events() {
    document
      .querySelector(".woocommerce")
      .addEventListener("click", (e) => this.handleQuantity(e));
  }

  handleQuantity(e) {
    const target = e.target;
    if (
      target.matches(".quantity__selector__wrapper .minus") ||
      target.matches(".quantity__selector__wrapper .plus")
    ) {
      let qty = e.target
        .closest(".quantity__selector__wrapper")
        .querySelector(".qty");
      let val = parseFloat(qty.value);
      let max = parseFloat(qty.getAttribute("max"));
      let min = parseFloat(qty.getAttribute("min"));
      let step = parseFloat(qty.getAttribute("step"));

      if (target.classList.contains("plus")) {
        if (max && max <= val) {
          qty.value = max;
        } else {
          qty.value = val + step;
          if (document.querySelector('[name="update_cart"]')) {
            this.simulateClickUpdateCartBtn();
          }
        }
      } else {
        if (min && min >= val) {
          qty.value = min;
        } else if (val > 1) {
          qty.value = val - step;
          if (document.querySelector('[name="update_cart"]')) {
            this.simulateClickUpdateCartBtn();
          }
        }
      }
    }
  }

  simulateClickUpdateCartBtn() {
    const updateBtn = document.querySelector('[name="update_cart"]');
    let timeout;
    updateBtn.removeAttribute("disabled");
    updateBtn.setAttribute("aria-disabled", false);
    updateBtn.setAttribute("clicked", true);
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function () {
      updateBtn.click();
    }, 500);
  }
}

export default QuantitySelector;
