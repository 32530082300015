class CustomDropdown {
  constructor(wrapper) {
    this.wrapper = wrapper; // the .woo-variation-items-wrapper element
    this.ul = this.wrapper.querySelector("ul");
    this.items = this.wrapper.querySelectorAll("li");
    this.rawSelect = this.wrapper.querySelector("select");
    this.label = this.wrapper.parentElement.querySelector("label");

    this.currentValue = null;

    if (this.ul && this.items.length) {
      this.updateSpans(); // New method to update spans based on data-title
      this.events();
    }
  }

  events() {
    // Set the initial value of the label to the selected option
    this.updateLabel();

    // display the dropdown
    this.wrapper.classList.add("visible");

    if (this.items.length === 1) {
      this.label.classList.add("unique");
    }

    // Handle the click on the dropdown to show options
    this.wrapper.addEventListener("click", (e) => {
      if (!this.label.classList.contains("unique")) {
        // Toggle active class to show/hide the ul
        this.wrapper.classList.toggle("active");
      }
    });

    // Handle selecting an option
    this.items.forEach((item) => {
      item.addEventListener("click", (e) => this.handleItemClick(e, item));
    });

    // Close the dropdown if clicked outside
    document.addEventListener("click", (e) => {
      if (!this.wrapper.contains(e.target)) {
        this.wrapper.classList.remove("active");
      }
    });
  }

  // New method to update spans based on data-title attribute
  updateSpans() {
    this.items.forEach((item) => {
      const title = item.getAttribute("data-title");
      const span = item.querySelector(".variable-item-span");
      if (span && title) {
        span.textContent = title; // Set the span content to the data-title value
      }
    });
  }

  handleItemClick(e, item) {
    const value = item.getAttribute("data-value");

    // Update raw <select> value
    this.rawSelect.value = value;

    // Remove "selected" class from all items
    this.items.forEach((item) => item.classList.remove("selected"));

    // Add "selected" class to the clicked item
    item.classList.add("selected");

    // Update the label text with the selected value
    this.updateLabel();

    // Set the current value
    this.currentValue = value;
  }

  updateLabel() {
    const selectedItem = this.wrapper.querySelector("li.selected");

    const labelValue = selectedItem
      ? selectedItem.getAttribute("data-title")
      : "Choose an option"; // Fallback if no option is selected
    this.label.textContent = labelValue;
  }
}

export default CustomDropdown;
