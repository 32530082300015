// core version + navigation, pagination modules:
import Swiper, { Navigation } from "swiper";

class ImageSlider {
  constructor() {
    const sliders = document.querySelectorAll(".image-slider");

    if (sliders) {
      sliders.forEach((element) => {
        document.addEventListener("DOMContentLoaded", function () {
          const swiper = new Swiper(element, {
            modules: [Navigation],
            slidesPerView: 1.1,
            initialSlide: 0,
            loop: true,
            spaceBetween: 0,
            draggable: true,
            grabCursor: true,
            keyboard: true,
            navigation: {
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            },
            breakpoints: {
              768: {
                slidesPerView: "auto",
              },
            },
          });
        });
      });
    }
  }
}

export default ImageSlider;
