class SearchBar {
  constructor() {
    this.searchToggleBtn  = document.querySelector(".search__trigger")
    this.searchBar        = document.querySelector(".search__wrapper")
    this.searchInput      = document.querySelector(".search__input");

    if (this.searchToggleBtn) {
      this.events()
    }

  }

  events() {
    this.searchToggleBtn.addEventListener("click", () => this.toggleSearchBar())
  }

  toggleSearchBar() {
    if (!this.searchToggleBtn.classList.contains('open')) {
      this.focusInput();
    }
    this.searchToggleBtn.classList.toggle("is-active");
    this.searchBar.classList.toggle("is-active")
  }

  focusInput() {
    this.searchInput.focus();
  }


}

export default SearchBar
