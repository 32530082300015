class FormToggle {
  constructor() {
    this.toggleForm = document.getElementById("toggle-form-btn");
    this.toggleLogin = document.getElementById("login-btn");
    this.toggleRegister = document.getElementById("register-btn");
    this.loginForm = document.getElementById("login-form");
    this.registerForm = document.getElementById("register-form");

    if (this.toggleForm && this.toggleLogin && this.toggleRegister) {
      this.events();
    }
  }

  events() {
    this.toggleForm.addEventListener("click", (e) => this.handleClick(e));
  }

  handleClick(e) {
    const target = e.target;

    if (target === this.toggleLogin) {
      this.showLoginForm();
    } else if (target === this.toggleRegister) {
      this.showRegisterForm();
    }
  }

  showLoginForm() {
    this.loginForm.classList.add("is-active");
    this.toggleLogin.classList.add("active");
    this.toggleRegister.classList.remove("active");
    this.registerForm.classList.remove("is-active");
  }

  showRegisterForm() {
    this.registerForm.classList.add("is-active");
    this.toggleRegister.classList.add("active");
    this.toggleLogin.classList.remove("active");
    this.loginForm.classList.remove("is-active");
  }
}

export default FormToggle;
