import "./css/style.scss";

// Modules/Classes
import MobileMenu from "./js/modules/MobileMenu";
import SearchBar from "./js/modules/SearchBar";
import VideoPlayer from "./js/modules/VideoPlayer";
import ImageSlider from "./js/modules/ImageSlider";
import SmoothScrolling from "./js/modules/SmoothScrolling";
import QuantitySelector from "./js/modules/QuantitySelector";
import Countdown from "./js/modules/Countdown";
import FormToggle from "./js/modules/FormToggle";
import ShippingAddressToggle from "./js/modules/ShippingAddressToggle";
import HeaderVisibilityToggle from "./js/modules/HeaderVisibilityToggle";
import AddToCart from "./js/modules/AddToCart";
import FixedElementsToggle from "./js/modules/FixedElementsToggle";

// Custom dropdowns
import CustomDropdown from "./js/modules/CustomDropdown";

let counter = 0;
const maxRetries = 10;

function initializeCustomDropdowns() {
  const customDropdowns = document.querySelectorAll(
    ".woo-variation-items-wrapper"
  );

  if (customDropdowns.length > 0) {
    // Initialize the custom dropdowns
    customDropdowns.forEach((wrapper) => new CustomDropdown(wrapper));
  } else {
    counter++;
    if (counter < maxRetries) {
      // Retry after 250ms if not found
      setTimeout(initializeCustomDropdowns, 250);
    }
  }
}

// Initialize after DOM content is fully loaded
document.addEventListener("DOMContentLoaded", initializeCustomDropdowns);

// Init Objects from Classes
const mobileMenu = new MobileMenu();
const searchBar = new SearchBar();
const videoPlayer = new VideoPlayer();
const imageSlider = new ImageSlider();
const smoothScrolling = new SmoothScrolling();
const quantitySelector = new QuantitySelector();
const countdown = new Countdown();
const formToggle = new FormToggle();
const shippingAddressToggle = new ShippingAddressToggle();
const headerVisibilityToggle = new HeaderVisibilityToggle();
const addToCart = new AddToCart();
const fixedElementsToggle = new FixedElementsToggle();

// LazyLoading
import lozad from "lozad";
const lazyLoadObserver = lozad(".lozad", {
  rootMargin: "500px 0px", // syntax similar to that of CSS Margin
  threshold: 0, // ratio of element convergence
  loaded: function (el) {
    el.classList.add("loaded");
    el.classList.remove("lozad");
  },
});
lazyLoadObserver.observe();

// Small Functions
// Fix Transitions Bug on load
window.onload = function () {
  document.querySelector("body").classList.remove("preload");
};

// Loading Animation: removal from DOM after end of animation
const frontpage = document.querySelector(".home");

if (frontpage) {
  window.addEventListener("load", function () {
    const rotateDiv = document.querySelector(".rotate");
    rotateDiv.classList.remove("hidden");

    rotateDiv.addEventListener("animationend", function () {
      rotateDiv.remove();
    });
  });
} else {
  const rotateDiv = document.querySelector(".rotate");
  rotateDiv.remove();
}
