class Countdown {
  constructor() {
    this.countdownElements = document.querySelectorAll(".countdown-timer");

    if (this.countdownElements.length > 0) {
      this.events();
    }
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      const updateCountdown = (domElement, targetDate, intervalId) => {
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0) {
          const finalPhrase =
            domElement.getAttribute("data-phrase") || "Time's up!";
          const finalUrl = domElement.getAttribute("data-url");

          domElement.textContent = finalPhrase;

          if (finalUrl) {
            domElement.href = finalUrl;
          }

          clearInterval(intervalId);
        } else {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          domElement.textContent = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      this.countdownElements.forEach((el) => {
        const date = el.getAttribute("data-date");
        const targetDate = new Date(date);
        const intervalId = setInterval(
          () => updateCountdown(el, targetDate, intervalId),
          1000
        );
        updateCountdown(el, targetDate, intervalId);
      });
    });
  }
}

export default Countdown;
