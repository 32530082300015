class ShippingAddressToggle {
  constructor() {
    // Select the wrapper
    this.wrapper = document.querySelector(".woocommerce-shipping-fields");

    // Select the toggle button and shipping address form within the wrapper
    this.toggleButton = this.wrapper
      ? this.wrapper.querySelector("#ship-to-different-address")
      : null;
    this.shippingAddressForm = this.wrapper
      ? this.wrapper.querySelector(".shipping_address")
      : null;

    if (this.toggleButton && this.shippingAddressForm) {
      this.events();
    }
  }

  events() {
    this.toggleButton.addEventListener("click", () => this.handleToggle());
    // Initial check to set the class based on the current state
    this.handleToggle();
  }

  handleToggle() {
    // Get the computed style of the shipping address form
    const computedStyle = window.getComputedStyle(this.shippingAddressForm);
    const isClosed =
      computedStyle.display !== "none" && computedStyle.visibility !== "hidden";

    if (isClosed) {
      this.toggleButton.classList.remove("opened");
    } else {
      this.toggleButton.classList.add("opened");
    }
  }
}

export default ShippingAddressToggle;
