class AddToCart {
  constructor() {
    this.banner = document.getElementById("custom-banner");
    this.elementToChange = document.querySelector(".cart__indicator__mobile");
    this.body = document.body;
    this.addToCartButtons = document.querySelectorAll(".add_to_cart_button");

    this.init();
  }

  init() {
    if (
      this.body.classList.contains("product-added-to-cart") ||
      (typeof productAddedToCart !== "undefined" && productAddedToCart)
    ) {
      this.handleProductAdded();
    }

    this.addToCartButtons.forEach((button) => {
      button.addEventListener("click", (e) => this.handleAddToCart(e));
    });

    // Listen for custom event from WooCommerce
    document.addEventListener("added_to_cart", () => this.handleProductAdded());
  }

  handleProductAdded() {
    this.showBanner();
    this.changeElementColor();
    this.setHideTimer();
  }

  showBanner() {
    if (this.banner) {
      this.banner.style.display = "block";
      this.banner.style.opacity = "1";
    }
  }

  changeElementColor() {
    if (this.elementToChange) {
      this.elementToChange.style.backgroundColor = "#6C8DFF";
    }
  }

  setHideTimer() {
    setTimeout(() => this.hideBanner(), 2500);
  }

  hideBanner() {
    if (this.banner) {
      this.banner.style.transition = "opacity 0.5s ease";
      this.banner.style.opacity = "0";
      setTimeout(() => {
        this.banner.style.display = "none";
      }, 500);
    }

    this.resetElementColor();
    this.removeBodyClass();
  }

  resetElementColor() {
    if (this.elementToChange) {
      this.elementToChange.style.backgroundColor = "rgba(0, 0, 0, 1)";
    }
  }

  removeBodyClass() {
    this.body.classList.remove("product-added-to-cart");
  }

  handleAddToCart(e) {
    // We're not preventing default here, allowing WooCommerce to handle the add to cart action
    // Instead, we'll wait for the 'added_to_cart' event to trigger our banner
  }
}

// // Initialize the AddToCart class
// document.addEventListener("DOMContentLoaded", () => {
//   new AddToCart();
// });

export default AddToCart;
