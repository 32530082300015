class HeaderVisibilityToggle {
  constructor() {
    this.header = document.querySelector("header");
    this.lastScrollTop = 0;
    this.scrollThreshold = 5;

    if (this.header) {
      this.events();
    }
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      window.addEventListener("scroll", this.handleScroll.bind(this));

      // Initial call to set the correct state on page load
      this.handleScroll();
    });
  }

  handleScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Check if we're scrolling up or if we're at the top of the page
    if (scrollTop < this.lastScrollTop || scrollTop <= 0) {
      this.header.classList.add("visible");
    } else if (
      scrollTop > this.lastScrollTop &&
      scrollTop > this.scrollThreshold
    ) {
      this.header.classList.remove("visible");
    }

    this.lastScrollTop = scrollTop;
  }
}

export default HeaderVisibilityToggle;
