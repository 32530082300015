class FixedElementsToggle {
  constructor() {
    this.couponElement = document.querySelector(".coupon");
    this.cartTotalsElement = document.querySelector(".cart-collaterals");
    this.footerElement = document.querySelector("footer");
    this.overlayOffset = -25; // Adjust this value to change the overlap trigger point

    if (this.couponElement && this.cartTotalsElement && this.footerElement) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    document.addEventListener("DOMContentLoaded", () => {
      this.checkOverlap();
      window.addEventListener("scroll", () => this.checkOverlap());
    });
  }

  checkOverlap() {
    const couponRect = this.couponElement.getBoundingClientRect();
    const cartTotalsRect = this.cartTotalsElement.getBoundingClientRect();
    const footerRect = this.footerElement.getBoundingClientRect();

    const couponOverlaps =
      couponRect.bottom - this.overlayOffset > footerRect.top;
    const cartTotalsOverlaps =
      cartTotalsRect.bottom - this.overlayOffset > footerRect.top;

    if (couponOverlaps || cartTotalsOverlaps) {
      this.couponElement.classList.add("overlap");
      this.cartTotalsElement.classList.add("overlap");
    } else {
      this.couponElement.classList.remove("overlap");
      this.cartTotalsElement.classList.remove("overlap");
    }
  }
}

export default FixedElementsToggle;
